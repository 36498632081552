// import { initializeApp } from "firebase/app";
// const firebaseConfig = {
//     apiKey: "AIzaSyA1dQ47y90or7Ph_pRDmEapQdDiGJsAvwo",
//     authDomain: "qrdoorman-ebf21.firebaseapp.com",
//     databaseURL: "https://qrdoorman-ebf21-default-rtdb.firebaseio.com",
//     projectId: "qrdoorman-ebf21",
//     storageBucket: "qrdoorman-ebf21.appspot.com",
//     messagingSenderId: "947594251517",
//     appId: "1:947594251517:web:f128cb398b14e492ca0294",
//     measurementId: "G-EMP6RHSZ2V"
//   };

// export const app = initializeApp(firebaseConfig);




import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDco241iGfaKFDDV6mOV164ad3fyCgeGRY",
  authDomain: "qrdoormanv3.firebaseapp.com",
  databaseURL: "https://qrdoormanv3-default-rtdb.firebaseio.com",
  projectId: "qrdoormanv3",
  storageBucket: "qrdoormanv3.appspot.com",
  messagingSenderId: "15402799821",
  appId: "1:15402799821:web:ee89f06df088e7f0b10f84",
  measurementId: "G-Q8KD0LQ81Z"
};


export const app = initializeApp(firebaseConfig);

